import React from "react";

function Bannerad() {
  return (
    <>
      <div className="col-lg-12 ad-widget-wrap mt-30 mb-30">
        <img
          className="img-fluid"
          src="https://placehold.co/800x300/gray/white"
          alt=""
        />
      </div>
    </>
  );
}

export default Bannerad;
