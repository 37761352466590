import React, { useEffect, useState } from "react";
import axios from "axios";
import { Outlet, Link } from "react-router-dom";
import "../stylesheet/latest.css";

function Latest() {
  const [latnews, setLatnews] = useState([]);
  const [firstNews, setFirstNews] = useState(null);
  console.log(firstNews);
  const [secondData, setSecondData] = useState(null);
  const [thirdData, setThirdData] = useState(null);

  useEffect(() => {
    //  TRENDING #1 AXIO CALL
    axios
      .get("http://admin.blossomdigitalmedia.com/api/usermodulenews")
      .then((res) => res.data.data)
      .then((latnews) => {
        setLatnews(latnews);
        if (latnews.length > 0) {
          const firstNewsItem = {
            ...latnews[0],
            firstImage:
              latnews[0].imagevideo.length > 0
                ? `https://admin.blossomdigitalmedia.com/uploads/newsImage/${latnews[0].imagevideo[0].newsImageVideo}`
                : null,
          };
          return firstNewsItem;
        } else {
          return null;
        }
      })
      .then((firstNewsItem) => {
        setFirstNews(firstNewsItem);
        // console.log(firstNewsItem);
      })
      .catch((error) => {
        // Handle error
      });
    //  TRENDING #2 AXIO CALL
    axios
      .get("http://admin.blossomdigitalmedia.com/api/usermodulenews")
      .then((res) => res.data.data)
      .then((data) => {
        if (data.length > 1) {
          const secondNewsItem = {
            ...data[1],
            secondImage:
              data[1].imagevideo.length > 0
                ? `https://admin.blossomdigitalmedia.com/uploads/newsImage/${data[1].imagevideo[0].newsImageVideo}`
                : null,
          };
          setSecondData(secondNewsItem);
          // console.log(secondNewsItem);
        } else {
          // Handle the case when the array has less than 2 items
          console.warn("The array has less than two items.");
        }
      })
      .catch((error) => {
        // Handle error here
        console.error("Error fetching data:", error);
      });
    //  TRENDING #3 AXIO CALL
    axios
      .get("http://admin.blossomdigitalmedia.com/api/usermodulenews")
      .then((res) => res.data.data)
      .then((data) => {
        if (data.length > 2) {
          const thirdNewsItem = {
            ...data[2],
            thirdImage:
              data[2].imagevideo.length > 0
                ? `https://admin.blossomdigitalmedia.com/uploads/newsImage/${data[2].imagevideo[0].newsImageVideo}`
                : null,
          };
          setThirdData(thirdNewsItem);
          // console.log(thirdNewsItem);
        } else {
          // Handle the case when the array has less than 3 items
          console.warn("The array has less than three items.");
        }
      })
      .catch((error) => {
        // Handle error here
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <div className="site-main-container">
        <section className="top-post-area pt-10">
          <div className="container no-padding">
            <div className="row small-gutters">
              {/* TRENDING #1 NEWS */}
              {firstNews ? (
                <div className="col-lg-8 top-post-left">
                  <Link to={`/newscontent/${firstNews.newsId}`}>
                    <div className="feature-image-thumb relative">
                      <div className="overlay overlay-bg"></div>

                      <img
                        className="img-fluid fixed-size-image"
                        src={firstNews.firstImage}
                        alt=""
                      />
                    </div>
                    <div className="top-post-details">
                      <ul className="tags">
                        <li>
                          <a>#TRENDING 1</a>
                        </li>
                      </ul>
                      <a>
                        <h3>{firstNews.heading}</h3>
                      </a>
                      <ul className="meta">
                        <li>
                          <a>
                            <span className="lnr lnr-user"></span>
                            {firstNews.author} author
                          </a>
                        </li>
                        <li>
                          <a>
                            <span className="lnr lnr-calendar-full"></span>
                            {firstNews.newsDate}
                          </a>
                        </li>
                        <li>
                          <a>
                            <span className="lnr lnr-bubble"></span>
                            {firstNews.comments}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              ) : (
                <div className="col-lg-8 top-post-left">
                  <p>No data found</p>
                </div>
              )}
              {/* END TRENDING ONE NEWS */}
              {/* TRENDING TWO NEWS */}
              <div className="col-lg-4 top-post-right">
                <div className="single-top-post">
                  <div className="feature-image-thumb relative">
                    <div className="overlay overlay-bg"></div>
                    <img className="img-fluid" src="img/top-post2.jpg" alt="" />
                  </div>
                  <div className="top-post-details">
                    <ul className="tags">
                      <li>
                        <a>TRENDING TWO</a>
                      </li>
                    </ul>
                    <a>
                      <h4>A Discount Toner Cartridge Is Better Than Ever.</h4>
                    </a>
                    <ul className="meta">
                      <li>
                        <a>
                          <span className="lnr lnr-user"></span>Mark wiens
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="lnr lnr-calendar-full"></span>03
                          April, 2018
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="lnr lnr-bubble"></span>06 Comments
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* END TRENDING TWO NEWS */}

                {/* TRENDING THREE NEWS */}
                <div className="single-top-post mt-10">
                  <div className="feature-image-thumb relative">
                    <div className="overlay overlay-bg"></div>
                    <img className="img-fluid" src="img/top-post3.jpg" alt="" />
                  </div>
                  <div className="top-post-details">
                    <ul className="tags">
                      <li>
                        <a>trend 3</a>
                      </li>
                    </ul>
                    <a>
                      <h4>A Discount Toner Cartridge Is Better</h4>
                    </a>
                    <ul className="meta">
                      <li>
                        <a>
                          <span className="lnr lnr-user"></span>Mark wiens
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="lnr lnr-calendar-full"></span>03
                          April, 2018
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="lnr lnr-bubble"></span>06 Comments
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*END TRENDING THREE NEWS */}
              <div className="col-lg-12">
                <div className="news-tracker-wrap">
                  <h6>
                    <span>Breaking News:</span>
                    <a>Astronomy Binoculars A Great Alternative</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Latest;
