import React, { useState, useEffect, useRef } from "react";
import "../stylesheet/header.css";
import axios from "axios";
import { Outlet, Link } from "react-router-dom";

const Header = () => {
  const [isMobileNavActive, setMobileNavActive] = useState(false);
  // API FETCH
  const [menu, setmenu] = useState([]);
  const [logo, setlogo] = useState("");

  const handleMenuHasChildrenClick = (e) => {
    e.currentTarget.classList.toggle("menu-item-active");
    const siblingUl = e.currentTarget.nextElementSibling;
    if (siblingUl) {
      siblingUl.classList.toggle("slide-toggle");
    }
    e.currentTarget.classList.toggle("lnr-chevron-up");
    e.currentTarget.classList.toggle("lnr-chevron-down");
  };

  const handleMobileNavToggleClick = () => {
    setMobileNavActive((prev) => !prev);
  };

  useEffect(() => {
    axios
      .get("http://admin.blossomdigitalmedia.com/api/usermodulecategory")
      .then((res) => {
        setmenu(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("http://admin.blossomdigitalmedia.com/api/usermodulelogo")
      .then((res) => {
        setlogo(res.data.data.companyLogo);
        // console.log(logo);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <header>
      <div className="header-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 header-top-left no-padding">
              <ul>
                <li>
                  <a>
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a>
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a>
                    <i className="fa fa-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a>
                    <i className="fa fa-behance"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 header-top-right no-padding">
              <ul>
                <li>
                  <a>
                    <span className="lnr lnr-phone-handset"></span>
                    <span>+123 456 7890</span>
                  </a>
                </li>
                <li>
                  <a>
                    <span className="lnr lnr-envelope"></span>
                    <span>
                      <span
                        className="__cf_email__"
                        data-cfemail="57242227273825231734383b38253b3e357934383a"
                      >
                        [email&#160;protected]
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="logo-wrap">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-4 col-md-4 col-sm-12 logo-left no-padding">
              <Link to="/">
                <a>
                  <img
                    className="img-fluid"
                    id="logo-main"
                    src={`https://admin.blossomdigitalmedia.com/uploads/logo/${logo}`}
                    alt=""
                  />
                </a>
              </Link>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 logo-right no-padding ads-banner">
              <img
                className="img-fluid"
                src="https://placehold.co/500x100/gray/white"
                alt=""
              ></img>
            </div>
          </div>
        </div>
      </div>
      {/* NAVBAR WITH MOBILE TOGGLE SECTION */}
      <div
        className={`container main-menu ${
          isMobileNavActive ? "mobile-nav-active" : ""
        }`}
        id="main-menu"
      >
        <div className="row align-items-center justify-content-between">
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              {/* Add your menu items here */}

              {menu.map((menus) => {
                return (
                  <li class="menu-active" key={menus.categoryId}>
                    <Link to={`/categorylist/${menus.categoryId}`}>
                      <a>{menus.categoryName}</a>
                    </Link>
                  </li>
                );
              })}

              {/* Add more menu items here */}
            </ul>
          </nav>
          <div className="navbar-right">{/* Add your search form here */}</div>
        </div>
        <button
          type="button"
          id="mobile-nav-toggle"
          onClick={handleMobileNavToggleClick}
        >
          <i
            className={`lnr ${isMobileNavActive ? "lnr-cross" : "lnr-menu"}`}
          ></i>
          <span className="menu-title">Menu</span>
        </button>
        <div id="mobile-body-overly" onClick={handleMobileNavToggleClick}></div>
      </div>
    </header>
  );
};

export default Header;
