import React from "react";

function Breaknews() {
  return (
    <>
      <div className="col-lg-12">
        <div className="news-tracker-wrap">
          <h6>
            <span>Breaking News:</span>
            Astronomy Binoculars A Great Alternative -------final check -------
          </h6>
        </div>
      </div>
    </>
  );
}

export default Breaknews;
